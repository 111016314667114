import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Hero from '../components/Hero'
import Main from '../components/Main'
import styled from 'react-emotion'
import Button from '../components/Button'

import imgThankyou from '../assets/bruno-van-der-kraan-699926-unsplash.jpg'

import Layout from '../components/layout'
import { rhythm } from '../utils/typography'

const PanelBody = styled.div`
  margin: 0 auto;
  max-width: 600px;
`

const Panel = styled('div')`
  padding: 80px 0;
  &.alt {
    background-color: #F5F8FC;
  }

  & h1 {
    // padding-bottom: 40px;
  }

  & p {
    margin-bottom: 40px;
  }

  label {
    display: block;
    font-size: .875rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    text-align: left;
    text-transform: uppercase;
    width: 100%;

    input {
      font-size: 1.5rem;
      padding: 0.5rem 1rem;
      text-transform: none;
      width: 100%;
    }

    &.bot-field {
      opacity: 0;
      display: none;
    }
  }

  button {
    background: #216EDC;
    border: none;
    border-radius: 3px;
    color: #fff;
    font-weight: 600;
    padding: 1rem 1rem;
    text-transform: uppercase;
    width: 100%;
  }
`

export default () => (
  <Layout>
    <Hero
      cover={imgThankyou}
      headText="Thank You"
      subheadText="We'll be in touch!"></Hero>
    <Main>
      <Panel>
        <PanelBody>
          <h1>Thank you for getting in touch with us!</h1>
          <p>We'll be in touch to answer your partnership questions as soon as possible.</p>
        </PanelBody>
      </Panel>
    </Main>
  </Layout>
)
