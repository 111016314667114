import React from 'react'
import { Link } from 'gatsby'
import styled from 'react-emotion'

const Button = styled('button')`
  background-color: #fff;
  border: 1px solid #216EDC;
  border-radius: 3px;
  color: #216EDC;
  font-family: "Montserrat", sans-serif;
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.05rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  text-transform: uppercase;
  width: 90%;
  max-width: 600px;

  &.primary {
    background-color: #216EDC;
    color: #fff;
  }
`

export default ({ children, style, disabled, type }) => (
  <Button
    type={type}
    disabled={disabled}
    className={style}
  >
    {children}
  </Button>
)
